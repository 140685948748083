const ABACUS_TEAM_ID_LENGTH = 20

export const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000
export const validPermissionGroup = new RegExp(
    `^amzn1.abacus.team.([a-z0-9]{${ABACUS_TEAM_ID_LENGTH}})$`,
)

export const USER_GUIDE_LINK =
    'https://core-ar.bravais.com/api/dynamic/documentVersions/4446/files/242295/?destination=Item1-1'
export const VIDEO_TUTORIALS_LINK = 'https://broadcast.amazon.com/channels/22480/playlists/44247'
export const DESCRIPTION_LENGTH_LIMIT = 50
export const PHONE_TOOL_PREFIX = 'https://phonetool.amazon.com/users/'
export const PERMISSION_GROUP_PREFIX = 'https://permissions.amazon.com/a/team/'
export const SELECT_BUSINESS_ENTITY = 'Select Business Entity'
// used if user has never set a BE previously
export const NO_USER_BUSINESS_ENTITY = 'NoUserBusinessEntity'
export const SELECT_ROLE = 'Select Role'
export const GLOBAL_BUSINESS_ENTITY = 'GLOBAL'
export const ACCESS_CONTROL_VALIDATION =
    'Invalid access control. Enter in the form amzn1.abacus.team.m435lggpabbcz4w47c7a'
export const ORG_NAME_VALIDATION_MESSAGE = 'Organization name must be provided.'
export const GROUP_NAME_VALIDATION_MESSAGE = 'Group name must be provided.'
export const GROUP_NAME_DUPLICATE_MESSAGE = 'That group name is taken. Try another.'
export const ORG_NAME_DUPLICATE_MESSAGE = 'That organization name is taken. Try another.'
export const EMPTY_PERMISSION_GROUP_VALIDATION = 'Permission group must be provided.'
export const SYS_USER = 'sys-user'

export const PLAN = 'plan'
export const REVISION = 'revision'
export const ORG = 'org'
export const DISCRETIONARY_SPEND = 'discretionary-spend'
export const CREATE = 'create'
export const MONTHS_NUMBER = 12
export const NOT_APPLICABLE = 'N/A'
export const EMPLOYEE_TYPE_GENERIC = ['employee', 'other', 'total_headcount', 'ct', 'ff']
export const EMPLOYEE_TYPE_DISPLAY_NAMES = ['C&T', 'FF']
export const EMPLOYEE_MEMBER_DETAIL_TABS = ['ct', 'ff']
export const EMPTY_SELECTION = {
    label: 'Select Option',
    value: '',
}
export const EMPTY_REVISION_SELECTION = {
    label: 'No Revision Found',
    value: '',
}

export const EMPTY_PLAN_SELECTION = {
    label: 'No Plan Found',
    value: '',
}

export const ALL_GROUP_SELECTION = {
    label: 'All Group',
    value: 'all_group',
}

export const SELECT_MOVE_PROGRAM_OPTION = {
    label: 'Select move to program',
    value: 'select_move_to_program',
}
export const ARRAY_VALUE_COLUMNS = ['registered_users', 'stl_alias']

export const EMPTY_HR_DATA = {
    entity_id: '',
    entity_name: '',
    entity: '',
    hr_permission_group: '',
    hr_permission_group_name: '',
    employee: 0,
    other: 0,
    total_headcount: 0,
    ct: 0,
    ff: 0,
}

export const HR_MEMBER_DATA_KEY = [
    'team_id',
    'team_name',
    'hr_permission_group',
    'hr_permission_group_name',
    'status',
    'resource_name',
    'alias',
    'manager_alias',
    'business_title',
    'employee_type',
]

export const enum MODAL_MODES {
    EDIT = 'EDIT',
    CREATE = 'CREATE',
    NOT_SET = 'NOT SET',
    DELETE = 'DELETE',
    VIEW = 'VIEW',
}

export const enum ALERT_TYPES {
    INFO = 'info',
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
}

export const enum STATUS_INDICATOR_TYPES {
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
    INFO = 'info',
    STOPPED = 'stopped',
    PENDING = 'pending',
    IN_PROGRESS = 'in-progress',
    LOADING = 'loading',
}

export const enum PROGRAM_ATTRIBUTE_TYPES {
    CORE = 'core',
    GLOBAL = 'global',
    LOCAL = 'local',
}

export const LIVE_HR_TEAM_DATA_KEY = [
    'entity_id',
    'entity_name',
    'entity',
    'hr_permission_group',
    'hr_permission_group_name',
    ...EMPLOYEE_TYPE_GENERIC,
]

export const enum COMPONENT_TYPES {
    INPUT_STRING = 'Input string',
    INPUT_INT = 'Input integer',
    INPUT_FLOAT = 'Input float',
    TOGGLE = 'Toggle',
    TEXTAREA = 'Textarea',
    SELECT = 'Select',
    LINK = 'Link',
    DATE = 'Date',
    TOKEN_GROUP = 'TokenGroup',
    POPOVER = 'Popover',
}

export const enum DISPLAY_TYPES {
    STRING = 'String',
    BADGE = 'Badge',
    BUTTON = 'Button',
    NUMBER = 'Number',
}

export const enum POPOVER_CONTENT_TYPES {
    TABLE = 'Table',
}

export const enum ENTITLEMENT_CURRENCY {
    MILLION = '$MM',
}

export const enum OBJECT_TYPE {
    PLAN = 'PLAN',
    REVISION = 'REVISION',
    PARENT_PROGRAM = 'PARENT_PROGRAM',
    PROGRAM = 'PROGRAM',
    DELIVERABLE = 'DELIVERABLE',
    HC_ESTIMATE = 'HC_ESTIMATE',
    DISCRETIONARY_SPEND = 'DISCRETIONARY_SPEND',
}

export const enum SUMMARY_HEADCOUNT_TYPE {
    CT = 'ct',
    FF = 'ff',
}

export const enum WEB_SOCKET_TYPE {
    HEADCOUNT_ESTIMATE_UPDATED = 'headcount_estimate_updated',
    HEADCOUNT_ESTIMATE_ADDED = 'headcount_estimate_added',
    HEADCOUNT_ESTIMATE_REVERTED = 'headcount_estimate_reverted',
    ROW_SELECTION_UPDATED = 'row_selection_updated',
    UPDATED_USER_LIST = 'updated_user_list',
    UPDATED_GROUP_LIST = 'updated_group_list',
    GROUP_SELECTION_UPDATED = 'group_selection_updated',
    UPDATE_GROUP_SELECTION = 'update_group_selection',
    UPDATE_HEADCOUNT_ESTIMATE = 'update_headcount_estimate',
    ADD_HEADCOUNT_ESTIMATE = 'add_headcount_estimate',
    UPDATE_ROW_SELECTION = 'update_row_selection',
    REVERT_HEADCOUNT_ESTIMATE = 'revert_headcount_estimate',
    GET_LATEST_STATE = 'get_latest_state',
    SUBMIT_STATUS_UPDATED = 'submit_status_updated',
    UPDATED_CELL_LIST = 'updated_cell_list',
}

export const enum GROUP_CELL_STATUS {
    LOCKED = 'locked',
    UNLOCKED = 'unlocked',
}

export const BUSINESS_ENTITY_ABBREVIATIONS = {
    AR: 'AR Product Engineering',
    GRD: 'Global Robotics Delivery',
}

export const getDefaultEstimate = () =>
    Object.fromEntries(EMPLOYEE_TYPE_GENERIC.map((employeeType) => [employeeType, '0']))

export enum SUMMARY_TABS {
    PROGRAM = 'Program',
    GROUP = 'Group',
    ORG = 'Organization',
    BE = 'Business Entity',
    PLAN = 'Plan',
    SPEND = 'Spend',
}

export const SUMMARY_TABS_OPTIONS = [
    { id: SUMMARY_TABS.PROGRAM, label: SUMMARY_TABS.PROGRAM },
    { id: SUMMARY_TABS.GROUP, label: SUMMARY_TABS.GROUP },
    { id: SUMMARY_TABS.ORG, label: SUMMARY_TABS.ORG },
    { id: SUMMARY_TABS.BE, label: SUMMARY_TABS.BE },
    { id: SUMMARY_TABS.PLAN, label: SUMMARY_TABS.PLAN },
    { id: SUMMARY_TABS.SPEND, label: SUMMARY_TABS.SPEND },
]
