import {
    Box,
    Button,
    Container,
    ContentLayout,
    Header,
    Select,
    SpaceBetween,
    Tabs,
    Toggle,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { ACTION_TYPES } from './useNewSummaryData'
import { formatRevisionOptions } from '../reusable/Utils'
import { useNewSummaryData } from './useNewSummaryData'
import { capitalizeFirstLetter } from '../../common/Util'
import NewOrgSummaryTable from './newSummaries/NewOrgSummary'
import { IconButton, Tooltip } from '@mui/material'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'
import SmsOutlined from '@mui/icons-material/SmsOutlined'
import { useEffect, useState } from 'react'
import { SUMMARY_TABS, SUMMARY_TABS_OPTIONS } from '../../Constant'

const headerBgColor = '#16191f'

const SummariesAudits = () => {
    const { data, dispatch } = useNewSummaryData()

    const queryParams = new URLSearchParams(location.search)
    const initialTab = queryParams.get('tab')

    const [selectedTab, setSelectedTab] = useState<SUMMARY_TABS>(
        initialTab ? (capitalizeFirstLetter(initialTab) as SUMMARY_TABS) : SUMMARY_TABS.PROGRAM,
    )

    const isLoading = () => {
        return (
            data[ACTION_TYPES.ORG_GROUP].loading ||
            data[ACTION_TYPES.DELIVERABLES].loading ||
            data[ACTION_TYPES.PLAN].loading ||
            data[ACTION_TYPES.HC].loading ||
            data[ACTION_TYPES.ORG].loading ||
            data[ACTION_TYPES.PROGRAM].loading
        )
    }

    useEffect(() => {
        if (!data.gridApi) {
            return
        }

        if (isLoading()) {
            data.gridApi.showLoadingOverlay()
        } else {
            data.gridApi.hideOverlay()
            if (data.gridApi.getDisplayedRowCount() === 0) {
                data.gridApi.showNoRowsOverlay()
            }
        }
    }, [
        data[ACTION_TYPES.ORG_GROUP].loading,
        data[ACTION_TYPES.PROGRAM].loading,
        data[ACTION_TYPES.DELIVERABLES].loading,
        data[ACTION_TYPES.PLAN].loading,
        data[ACTION_TYPES.HC].loading,
    ])

    return (
        <>
            <ContentLayout
                defaultPadding
                headerBackgroundStyle={headerBgColor}
                headerVariant='high-contrast'
                header={
                    <Box margin={{ left: 's', right: 's' }}>
                        <SpaceBetween size='xs' direction='vertical'>
                            <HeaderTemplate
                                title='Summaries & Audits'
                                subtitle='View organizational summary reports'
                                items={[
                                    { text: 'Home', href: '/' },
                                    { text: 'Summaries & Audits', href: '/summaries' },
                                    { text: `${selectedTab} Summary` },
                                ]}
                            />
                        </SpaceBetween>
                    </Box>
                }
            ></ContentLayout>
            <Container>
                <SpaceBetween size='xs' direction='vertical'>
                    {buildTableHeader(selectedTab, data, dispatch)}
                </SpaceBetween>

                <Tabs
                    onChange={({ detail }) => {
                        setSelectedTab(detail.activeTabId as SUMMARY_TABS)
                        queryParams.set('tab', detail.activeTabId)
                    }}
                    activeTabId={selectedTab}
                    tabs={SUMMARY_TABS_OPTIONS}
                />
                {buildSummaryTable(selectedTab, data, dispatch)}
            </Container>
        </>
    )
}

function buildTableHeader(type: SUMMARY_TABS, data, dispatch) {
    return (
        <>
            <Box>
                <Box float='left'>
                    <Header>{type} Summary</Header>
                </Box>
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='s'>
                        <Box padding={{ top: 's' }}>
                            <Tooltip
                                title={
                                    <Box variant='awsui-key-label' color='inherit'>
                                        History
                                    </Box>
                                }
                                placement='bottom'
                            >
                                <IconButton aria-label={'history'} size={'large'} color='primary'>
                                    <HistoryRoundedIcon fontSize='large' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title={
                                    <Box variant='awsui-key-label' color='inherit'>
                                        Comments
                                    </Box>
                                }
                                placement='bottom'
                            >
                                <IconButton aria-label={'comments'} size={'large'} color='primary'>
                                    <SmsOutlined fontSize='large' />
                                </IconButton>
                            </Tooltip>
                            <Button>HC Detail</Button>
                            <Button>Export</Button>
                        </Box>
                    </SpaceBetween>
                </Box>
            </Box>
            <SpaceBetween direction='horizontal' size='m'>
                <Select
                    filteringType={'auto'}
                    placeholder={'Selected Plan'}
                    selectedOption={data[ACTION_TYPES.PLAN].selected}
                    onChange={({ detail }) => {
                        const option: any = detail.selectedOption
                        const revisions: any = option?.data.revisions.map(formatRevisionOptions)
                        if (revisions.length) {
                            dispatch({
                                type: 'SET_MANY',
                                payload: {
                                    [ACTION_TYPES.REVISION]: [
                                        { key: 'options', data: revisions },
                                        { key: 'selected', data: revisions[0] },
                                    ],
                                },
                            })
                        } else {
                            dispatch({
                                type: 'SET_MANY',
                                payload: {
                                    [ACTION_TYPES.REVISION]: [
                                        { key: 'options', data: [] },
                                        {
                                            key: 'selected',
                                            data: {
                                                label: 'Select Revision',
                                                value: '',
                                            },
                                        },
                                    ],
                                },
                            })
                        }
                        dispatch({
                            type: ACTION_TYPES.PLAN,
                            payload: { key: 'selected', data: option },
                        })
                    }}
                    options={data[ACTION_TYPES.PLAN].options}
                    disabled={!data[ACTION_TYPES.REVISION].options.length}
                />
                <Select
                    filteringType={'auto'}
                    placeholder={'Selected Revision'}
                    selectedOption={data[ACTION_TYPES.REVISION].selected}
                    onChange={({ detail }) => {
                        dispatch({
                            type: ACTION_TYPES.REVISION,
                            payload: {
                                key: 'selected',
                                data: detail.selectedOption,
                            },
                        })
                    }}
                    options={data[ACTION_TYPES.REVISION].options}
                    disabled={!data[ACTION_TYPES.REVISION].options.length}
                />
                <Toggle
                    checked={data[ACTION_TYPES.DELIVERABLES].showScopedOnly}
                    onChange={({ detail }) => {
                        dispatch({
                            type: ACTION_TYPES.DELIVERABLES,
                            payload: {
                                key: 'showScopedOnly',
                                data: detail.checked,
                            },
                        })
                    }}
                >
                    scoped
                </Toggle>
            </SpaceBetween>
        </>
    )
}

function buildSummaryTable(type: SUMMARY_TABS, data, dispatch) {
    switch (type) {
        case SUMMARY_TABS.ORG:
            return <NewOrgSummaryTable data={data} dispatch={dispatch} />
        case SUMMARY_TABS.PROGRAM:
        case SUMMARY_TABS.GROUP:
        case SUMMARY_TABS.BE:
        case SUMMARY_TABS.PLAN:
        case SUMMARY_TABS.SPEND:
            return <></>
    }
}

export default SummariesAudits
