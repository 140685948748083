import { ModuleRegistry } from 'ag-grid-community'
import { ClientSideRowModelModule } from 'ag-grid-community'
import { ServerSideRowModelModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { ExcelExportModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { SetFilterModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { InfiniteRowModelModule } from 'ag-grid-community'
import { LicenseManager } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { agGridLicense } from '@amzn/ag-bird/src/ag-grid-license/ag-grid-license'
import { RowGroupingModule } from '@amzn/ag-bird/src/ag-grid-enterprise'

import { useEffect } from 'react'
import { ACTION_TYPES, SummaryData } from '.././useNewSummaryData'
import { AgGridReact } from 'ag-grid-react'
import {
    GRAND_TOTAL_COLOR_CODE,
    hideZerosAndTruncateNumbers,
    NORMAL_CELL_COLOR_CODE,
    orgSummaryInitialColDef,
} from '.././SummaryUtil'

LicenseManager.setLicenseKey(agGridLicense)

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ServerSideRowModelModule,
    ExcelExportModule,
    SetFilterModule,
    InfiniteRowModelModule,
    RowGroupingModule,
])

interface NewOrgSummaryProps {
    data: SummaryData
    dispatch: React.Dispatch<any>
}

// Maybe can just pass the data that is necessary for each table to do its thing
const NewOrgSummaryTable = ({ data, dispatch }: NewOrgSummaryProps) => {
    const onGridReady = (params: any) => {
        dispatch({ type: 'GRID_API', payload: params.api })
        params.api.autoSizeAllColumns()
    }

    useEffect(() => {
        const gridApi = data.gridApi
        if (gridApi && data[ACTION_TYPES.HC].data && data[ACTION_TYPES.DELIVERABLES].data) {
            const { flatData, groupOHData }: any = generateRows(
                data[ACTION_TYPES.HC].data,
                data[ACTION_TYPES.DELIVERABLES].data,
            )

            const { colDef, bisData, gapData }: any = generateColDef(groupOHData)

            gridApi.setGridOption('columnDefs', colDef)
            gridApi.setGridOption('rowData', flatData)

            gridApi.setGridOption('pinnedBottomRowData', [bisData, groupOHData, gapData])
        }
    }, [data[ACTION_TYPES.HC]])

    const generateColDef = (groupOHData) => {
        const ctColDef = {
            headerName: 'C&T',
            field: 'hc_ct',
            width: 100,
            cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            pinned: 'left',
        }

        const ffColDef = {
            headerName: 'FF',
            field: 'hc_ff',
            width: 100,
            cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            pinned: 'left',
        }

        const rowHCAggColDef = {
            headerName: `Total`,
            field: 'row_total',
            width: 100,
            aggFunc: 'sum',
            colId: 'TotalAggRow',
            cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            pinned: 'left',
            headerTooltip: 'Total (C&T + FF)',
        }

        const colDef = [...orgSummaryInitialColDef, ctColDef, ffColDef, rowHCAggColDef]

        const bisData = { program_name: 'BIS Total' }

        data[ACTION_TYPES.GROUPS_BY_ORG]?.data
            ? data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
                  const children: any = []
                  const orgId = org.orgId
                  const orgName = org.orgName
                  let orgFF = 0
                  let orgCT = 0

                  org.groups.forEach((group) => {
                      const groupColDef = {
                          columnGroupShow: 'open',
                          headerName: group.group_name,
                          field: `${group.group_id}`,
                          colId: `${group.group_id}_id`,
                          groupId: `${group.group_id}`,
                          valueFormatter: (params) => {
                              return params.value ? params.value : 0
                          },
                          width: 100,
                          headerTooltip: `${group.group_name}`,
                          children: [
                              {
                                  columnGroupShow: 'open',
                                  colId: `${group.group_id}_ct_id`,
                                  headerName: 'C&T',
                                  field: `${group.group_id}_ct`,
                                  aggFunc: 'sum',
                                  cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                              },
                              {
                                  columnGroupShow: 'open',
                                  colId: `${group.group_id}_ff_id`,
                                  headerName: 'FF',
                                  field: `${group.group_id}_ff`,
                                  aggFunc: 'sum',
                                  cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                              },
                          ],
                      }

                      children.push(groupColDef)
                      bisData[`${group.group_id}_ct`] =
                          'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
                      bisData[`${group.group_id}_ff`] =
                          'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0

                      orgFF += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0
                      orgCT += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
                  })

                  bisData[`${orgId}_ct`] = orgCT
                  bisData[`${orgId}_ff`] = orgFF
                  bisData[`${orgId}_total`] = orgCT + orgFF

                  children.push({
                      headerName: 'C&T',
                      field: `${orgId}_ct`,
                      colId: `${orgId}_ct_id`,
                      columnGroupShow: 'closed',
                      cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                  })
                  children.push({
                      headerName: 'FF',
                      field: `${orgId}_ff`,
                      colId: `${orgId}_ff_id`,
                      columnGroupShow: 'closed',
                      cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                  })
                  children.push({
                      headerName: 'Total',
                      field: `${orgId}_total`,
                      colId: `${orgId}_total_id`,
                      columnGroupShow: 'closed',
                      cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                  })

                  const col = {
                      headerName: orgName,
                      field: orgName,
                      colId: `${orgId}_id`,
                      groupId: `${orgId}_group_id`,
                      width: 100,
                      headerTooltip: orgName,
                      children: children,
                  }

                  colDef.push(col)
              })
            : null

        const gapData = {}
        Object.entries(bisData).forEach((value: any) => {
            const totalOHHC = groupOHData[value[0]] ?? 0
            const bisHC = value[1]
            gapData[value[0]] = totalOHHC - bisHC
        })
        gapData['program_name'] = 'Gap (Total w/ OH - BIS)'

        return { colDef, bisData, gapData }
    }

    const generateRows = (orgData, deliverables) => {
        const compressedOrgData = {}
        const allowedGroupIds = new Set() // think I don't need this.
        // Need to get all the org data, and organize it.
        // Seems like each item in the org data array is one deliverable associated with one group

        orgData.forEach((group) => {
            allowedGroupIds.add(group.group_id)
        })

        console.log('Calling generate rows')

        /*
        We have all the headcount

        */

        // Accumulate based on program_id with deliverables under each program
        orgData.forEach((row) => {
            // row is an org
            if (!compressedOrgData[row.program_id]) {
                compressedOrgData[row.program_id] = []
            }
            compressedOrgData[row.program_id].push({
                program_name: row.program_name,
                deliverable_id: row.deliverable_id,
                program_id: row.program_id,
                org_id: row.org_id,
                hc_ct: parseFloat(row.hc_ct),
                hc_ff: parseFloat(row.hc_ff),
                hc_ct_oh: parseFloat(row.hc_ct_overhead),
                hc_ff_oh: parseFloat(row.hc_ff_overhead),
                group_id: `${row.group_id}_id`,
            })
        })

        const groupOHData = { program_name: 'Total OH' }
        const programIdData = {}
        // Compress headcount estimate for different job functions
        Object.entries(compressedOrgData).forEach((program: any) => {
            const deliverableMap = {}
            for (let i = 0; i < program[1].length; i++) {
                const deliverable = program[1][i]
                if (!allowedGroupIds.has(deliverable.group_id)) {
                    continue
                }
                let deliverableMapVal = deliverableMap[deliverable.deliverable_id]
                if (!deliverableMapVal) {
                    deliverableMapVal = {
                        deliverable_id: deliverable.deliverable_id,
                        program_id: deliverable.program_id,
                        org_id: deliverable.org_id,
                        group_ids: [],
                    }
                }

                deliverableMapVal.group_ids.push(deliverable.group_id)
                if (!deliverableMapVal[`${deliverable.group_id}_ct`]) {
                    deliverableMapVal[`${deliverable.group_id}_ct`] = 0
                }

                if (!deliverableMapVal[`${deliverable.group_id}_ff`]) {
                    deliverableMapVal[`${deliverable.group_id}_ff`] = 0
                }

                deliverableMapVal[`${deliverable.group_id}_ct`] += +deliverable.hc_ct
                deliverableMapVal[`${deliverable.group_id}_ff`] += +deliverable.hc_ff

                deliverableMap[deliverable.deliverable_id] = deliverableMapVal

                if (!groupOHData[`${deliverable.group_id}_ct`]) {
                    groupOHData[`${deliverable.group_id}_ct`] = 0
                }
                if (!groupOHData[`${deliverable.group_id}_ff`]) {
                    groupOHData[`${deliverable.group_id}_ff`] = 0
                }

                groupOHData[`${deliverable.group_id}_ct`] += deliverable.hc_ct_oh
                groupOHData[`${deliverable.group_id}_ff`] += deliverable.hc_ff_oh
            }
            programIdData[program[0]] = deliverableMap
        })

        const flatData: any[] = []
        Object.entries(programIdData).forEach((program: any) => {
            for (let i = 0; i < Object.entries(program[1]).length; i++) {
                const row: any = Object.entries(program[1])[i][1]
                row['row_total'] = Object.keys(row).reduce((acc: any, val: any) => {
                    return acc + (val.endsWith('_ct') || val.endsWith('_ff') ? row[val] : 0)
                }, 0)
                row['program_name'] = data[ACTION_TYPES.PROGRAM].data[row['program_id']]
                row['deliverable_name'] = data[ACTION_TYPES.PROGRAM].data[row['deliverable_id']]

                row['hc_ct'] = 0
                row['hc_ff'] = 0

                if (
                    row['row_total'] !== 0 ||
                    (row['row_total'] === 0 && !data[ACTION_TYPES.DELIVERABLES].showScopedOnly)
                ) {
                    flatData.push(row)
                }
            }
        })

        if (!data[ACTION_TYPES.DELIVERABLES].showScopedOnly) {
            Object.entries(deliverables).forEach((programDelMap) => {
                const programName = programDelMap[0]
                const programDeliverables: any = programDelMap[1]
                Array.from(programDeliverables).forEach((deliverable: any) => {
                    if (!programIdData[deliverable.program_id]) {
                        flatData.push({
                            program_name: programName,
                            deliverable_name: deliverable.deliverable_name,
                            hc_ct: 0,
                            hc_ff: 0,
                            row_total: 0,
                        })
                    }
                })
            })
        }

        return { flatData, groupOHData }
    }

    return (
        <div
            className='ag-theme-quartz' // applying the grid theme
            style={{ height: '60vh', paddingTop: '20px' }} // the grid will fill the size of the parent container
        >
            <AgGridReact
                onGridReady={onGridReady}
                gridOptions={{
                    getRowStyle: (params) => {
                        return {
                            fontWeight:
                                params.node.rowPinned || params.node.footer ? 'bold' : 'normal',
                            background: params.node.footer
                                ? GRAND_TOTAL_COLOR_CODE
                                : NORMAL_CELL_COLOR_CODE,
                        }
                    },
                    pagination: true,
                    groupTotalRow: 'bottom',
                    grandTotalRow: 'bottom',
                    autoSizeStrategy: {
                        type: 'fitCellContents',
                    },
                    autoGroupColumnDef: {
                        headerName: 'Program',
                        field: 'program_name',
                        minWidth: 300,
                        pinned: 'left',
                    },
                    suppressAggFuncInHeader: true,
                    defaultColDef: {
                        lockPosition: true,
                        wrapHeaderText: true,
                        autoHeaderHeight: true,
                        initialWidth: 100,
                    },
                    tooltipShowDelay: 500,
                }}
            />
        </div>
    )
}

export default NewOrgSummaryTable
